import styled from "styled-components"
import { themeGet } from "styled-system"

const BannerWrapper = styled.div`
  padding-top: 100px;
  min-height: 100%;
  overflow: hidden;
  position: relative;

  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    /* -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -ms-animation-duration: 1s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        -ms-animation-fill-mode: forwards; */
  }

  .animatedFadeInUp {
    opacity: 1;
  }

  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(802px - 100px);
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
      min-height: calc(100vh - 100px);
      margin-top: 35px;
      align-items: flex-start;
    }
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }
  .glWaqu {
    @media only screen and (max-width: 425px) {
      margin-left: -2.8%;
      margin-top: -2.3%;
    }
  }

  .bannerBottomShape {
    position: absolute;
    left: 0;
    padding-left: 7.3%;
    padding-bottom: 4%;
    bottom: 0px;

    @media only screen and (max-width: 1306px) {
      display: none;
    }
  }
  .banner-btn-video {
    border: 0;
    color: #f9f9f9;
    padding: 0;
    width: 80%;
    height: 70px;
    border-radius: 5px;
    box-shadow: 0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);
    background-color: #98e044;
  }
  .banner-btn-download {
    border: 0;
    color: #f9f9f9;
    padding: 0;
    width: 80%;
    height: 70px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);
  }

  .bannerImageBtn {
    display: flex;
    margin-top: 5px;
    @media (max-width: 920px) {
      flex-direction: column;
      margin-left: 15%;
    }
    .a {
      width: 100%;
    }
    .app_image_area {
      margin-right: 15px;
      border-radius: 5px;
      margin-left: 22%;

      @media only screen and (max-width: 920px) {
        margin-top: 4%;
        margin-left: 0%;
      }
    }
    .flex-btn {
      width: 60%;
      @media (max-width: 920px) {
        width: 35%;
      }
      @media (max-width: 768px) {
        width: 40%;
      }

      @media (max-width: 425px) {
        width: 60%;
      }
    }
    .app_video_area {
      margin-bottom: 2%;

      @media only screen and (max-width: 920px) {
        /* margin-left: -1%; */
        margin-bottom: 2%;
      }
    }

    .ancher {
      @media only screen and (max-width: 1024px) {
        margin-right: -32px;
        margin-left: -51px;
      }
      @media only screen and (max-width: 920px) {
        margin-right: 20px;
        margin-left: 5px;
      }
    }
  }
`

export const BannerContent = styled.div`
  width: 25%;
  flex-shrink: 0;
  margin-top: -3%;
  margin-left: 0%;
  @media only screen and (max-width: 1440px) {
    width: 20%;
  }
  @media only screen and (max-width: 1199px) {
    flex-shrink: 0;
    width: 45%;
    margin-top: 18%;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0%;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 0%;
    width: 50%;
  }

  h1 {
    font-size: 46px;
    line-height: 55px;
    font-weight: 700;
    color: #98e044;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
    @media only screen and (max-width: 1440px) {
      width: 90%;
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
    }
    @media only screen and (max-width: 425px) {
      width: auto;
    }
  }
  h2 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 300;
    color: #b5b5b6;
    margin-bottom: 2%;
    letter-spacing: -1px;
    @media only screen and (max-width: 1440px) {
      letter-spacing: -0.7px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 27px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 38px;
      letter-spacing: -0.5px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: -0.5px;
      width: auto;
    }
  }
  h3 {
    font-size: 18.5;
    line-height: 1.75;
    font-weight: 350;
    text-align: justify;
    color: ${themeGet("colors.headingColor", "#b5b5b6")};
    margin-bottom: 10%;
    margin-top: 10%;
    @media only screen and (max-width: 1440px) {
      width: 90%;
    }

    @media only screen and (max-width: 991px) {
      font-size: 17.5px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 17.5px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 14px;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 29px;
  }
`
export const BannerImage = styled.div`
  flex-shrink: 0;
  margin-right: 28%;
  img {
    display: flex;
    @media only screen and (max-width: 1440px) {
      margin-top: 0px;
    }
    @media only screen and (max-width: 1024px) {
      margin-left: -5%;
      /*margin-top: 2%; */
      max-width: 88%;
    }
    @media only screen and (max-width: 850px) {
      /* margin-left: -5%;
      margin-top: 16%; */
      max-width: 68%;
    }
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
 
`
export const Down = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2%;
  margin-bottom: 2%;
  animation: shake 2.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite;
  @media (max-width: 768px) {
    display: none;
  }
  &:hover {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(0, 2px, 0);
    }
    20%,
    80% {
      transform: translate3d(0, 4px, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(0, -8px, 0);
    }
    40%,
    60% {
      transform: translate3d(0, 8px, 0);
    }
  }
`
export const ButtonGroup = styled.div`
  margin-top: 35px;
  .reusecore__button {
    // text-transform: inherit;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;

    &.primary {
      background-color: ${themeGet("colors.white", "#fff")};
      color: rgb(15, 33, 55);
      font-size: 16px;
      letter-spacing: -0.1px;
      &:hover {
        box-shadow: #1e2a4a 0px 12px 24px -10px;
      }
    }

    &.text {
      font-size: 16px;
      color: ${themeGet("colors.white", "#fff")};
      letter-spacing: -0.1px;
      font-weight: 500;
      .btn-icon {
        i {
          color: #fff;
        }
        svg {
          width: auto;
          height: 25px;
        }
      }
    }
  }
`
export const CustomerWrapper = styled.div`
  max-width: 850px;
  padding: 0px 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    padding: 0px 0 55px;
  }
  @media only screen and (max-width: 1099px) {
    padding: 55px 0 55px;
    max-width: 920px;
  }

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 25px 0 40px;
  }

  p {
    margin: 0 12px 0 0;
    color: ${themeGet("colors.menu", "#0D233E")};
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
  img {
    /* margin: 0px 12px; */
    @media only screen and (max-width: 667px) {
      margin: -1px 10px;
    }
  }
`

export const TestimonialSlideWrapper = styled.div`
  margin-top: 20%;
  margin-left: 30px;
  width: 140%;
  @media (max-width: 575px) {
    width: 180%;
    margin-bottom: 15%;
  }
  .glide__bullets {
    margin-left: 36px;
    text-align: left;
    @media (max-width: 1440px) {
      margin-left: 5%;
    }
    @media (max-width: 1200px) {
      margin-left: 2%;
    }
    @media (max-width: 768px) {
      margin-top: 0px;
      margin-left: 4%;
      width: 100%;
    }
    @media (max-width: 575px) {
      margin-top: 0px;
      margin-left: -4px;
      width: 100%;
    }
    .glide__bullet {
      width: 10px;
      height: 10px;
      background: #cbcbcb;
      margin: 4px;
      transition: 0.15s ease-in-out;
      &:hover {
        background: #98e044;
      }
      &.glide__bullet--active {
        background: #98e044;
      }
      @media (max-width: 575px) {
        width: 7px;
        height: 7px;
      }
    }
  }
`

export const TestimonialItem = styled.div`
  position: relative;
  display: block;
  background: transparent;
  font-family: Raleway;
  padding: 38px;
  @media (max-width: 1440px) {
    padding: 20px;
  }
  @media (max-width: 575px) {
    padding: 0px;
  }
`

export const TestimonialMeta = styled.div`
  display: flex;
  align-items: left;
  width: 100%;
  height: 100%;
`

export const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
`
export const ImageHolder = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`

export default BannerWrapper
